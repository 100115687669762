export default {
  computed: {
    getStatusColor(){
      return (status) => status ? "primary" : "warning"
    }
  },
  methods: {
    absoluteUrl(path = ""){
      return this.$store.state.domain + path
    },
    reviewFormart(reviews){
      if(!reviews){ reviews = []}
      let total = reviews.length
      let five = reviews.filter(r => r.rating == 5).length
      let four = reviews.filter(r => r.rating == 4).length
      let three = reviews.filter(r => r.rating == 3).length
      let two = reviews.filter(r => r.rating == 2).length
      let one = reviews.filter(r => r.rating == 1).length
      let average = total ? Math.round(((five*5+four*4+three*3+two*2+one*1)/total) * 10)/10 : 0;
      return `${average} (${total})`;
    },
    getExtension(filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    },
    isImageFile(ext){
      return  ['png','jpeg','svg','jpg','webp'].includes(ext ? ext.toLowerCase() : null)
    },
    preText(content) {
      if(!content) return;
      return content.replace(/\n/g, '<br />')
    },
    // isImage(filename) {
    //     var ext = this.getExtension(filename);
    //     switch (ext.toLowerCase()) {
    //         case 'jpg':
    //         case 'gif':
    //         case 'bmp':
    //         case 'png':
    //         //etc
    //         return true;
    //     }
    //     return false;
    // },
    // isVideo(filename) {
    //     var ext = this.getExtension(filename);
    //     switch (ext.toLowerCase()) {
    //         case 'm4v':
    //         case 'avi':
    //         case 'mpg':
    //         case 'mp4':
    //         // etc
    //         return true;
    //     }
    //     return false;
    // },
    calculateDeliveryTime(time){
      if(!time){return}
      return (time > 24) ? Math.round(time/24) + ' days' : '1 day'
    },
    scrollToReview(elID){
        document.getElementById(elID).scrollIntoView({behavior: "smooth"})
    },
    alertSuccess(text){
        toastr.success(text, 'Success');
    },
    alertError(text){
        toastr.error(text, 'Something went worry', {timeOut: 3000});
    },
    confirmDelete(option){
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: option.title || "Are you sure?",
          text: option.text,
          reverseButtons:true,
          showCancelButton: true,
          confirmButtonColor: "#e11",
          cancelButtonColor: "#111",
          confirmButtonText: "Delete it!",
        }).then((result) => {
          resolve(result)
        }).catch((error) => { reject(error) });
      })
    },
    confirmAction(option){
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: option.title || "Confirm action!",
          text: option.text,
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#0ec569",
          cancelButtonColor: "#111",
          confirmButtonText: "Yes, Proceed!",
        }).then((result) => {
          resolve(result)
        }).catch((error) => { reject(error) });
      })
    }
  }
};
